<script>
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    PageHeader,
  },
  data() {
    return {
      data: {},
      newLevels: [{}],
      selectedChannel: {},
      marketing_campaign_areas: [],
      marketing_campaign_channels: [],
      MarketingChannels: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchMarketingChannels: null,
      MarketingChannelsSearchMode: false,
      searchModel: "",

    };
  },
  methods: {
    addMarketingChannels() {
      if (this.newLevels.filter((i) => Object.keys(i).length == 0).length > 0)
        return alert("All Field required");
      this.data.levels = this.newLevels;
      console.log({ data: this.data });

      this.http.post("marketing-channels/", this.data, null, true).then((res) => {
        if (res.status) {

          this.popup.alert({ title: '', msg: 'popups.success' })
          this.data = {};
          this.newLevels = [{}];
          this.get(this.page);

          document.querySelector('#addModal .close-modal-btn').click()
        }
      });
    },
    editMarketingChannels() {
      console.log({ selectedChannel: this.selectedChannel });
      this.http
        .put(
          "marketing-channels",
          this.selectedChannel?.id,
          this.selectedChannel
        )
        .then((res) => {
          if (res.status) {
            this.popup.alert({ title: '', msg: 'popups.success' })
            this.data = {};
            this.newLevels = [{}];
            this.get(this.page);

            document.querySelector("#editModal .close-modal-btn").click();
          }
        });
    },
    search() {
      this.MarketingChannelsSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("marketing-channels/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          col: "name",
        }, null, true)
        .then((res) => {
          this.MarketingChannels = res.data;
          console.log("######## files", this.MarketingChannels);
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.MarketingChannelsSearchMode = false;
      this.get(this.page);
    },
    getMarketingChannels() {
      this.http.get("marketing-channels", null, null, true).then((res) => {
        this.MarketingChannels = res.data;
      });
    },
    setSelectedChannel(app) {
      this.selectedChannel = JSON.parse(JSON.stringify(app));
    },
    deleteMarketingChannels(app) {
      var data = {
        title: "popups.deletefile",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          console.log("###");
          this.http.delete("marketing-channels", app.id).then(() => {
            console.log("############################3");
            this.get(this.page);
          });
        }
      });
    },
    deleteLevel(level) {
      this.newLevels = this.newLevels.filter((i) => i != level);
    },
    deleteEdetedLevel(level) {
      this.selectedChannel.marketing_channel_levels =
        this.selectedChannel.marketing_channel_levels.filter(
          (i) => i != level
        );
    },
    get(page) {
      console.log(page);
      this.http
        .post("marketing-channels/pagination", {
          limit: this.limit,
          page: page,
        }, null, true)
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.MarketingChannels = res.data;
          // res.data.forEach((item) => {
          //   this.marketing_campaign_areas.push(item.marketing_campaign_areas);
          // });

          // res.data.forEach((item) => {
          //   this.marketing_campaign_channels.push(
          //     item.marketing_campaign_channels
          //   );
        });
      // });
    },
  },
  created() {
    this.get(1);
  },
  mounted() {


  },
};
</script>

<template>
  <PageHeader :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" :title="$t('menu.menuitems.marketing.subItems.marketing_channels_management')
    " />
  <div class="row">
    <div class="col-6">
      <div class="search-box chat-search-box w-50">
        <div class="position-relative">
          <input @keyup="search()" type="text" class="form-control fa-lg text-light"
            :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" :placeholder="$t('popups.search')" v-model="searchModel"
            style="background-color: #2a3042 !important" />
          <i class="bx bx-search-alt search-icon text-light"></i>
        </div>
      </div>
    </div>
    <div class="col-6" style="display: flex; justify-content: flex-end">
      <button class="btn-close text-light" v-if="MarketingChannelsSearchMode" @click="cancelappsearchMode()"></button>
      <button type="button" data-bs-toggle="modal" data-bs-target="#addModal" class="btn btn-light float-end mb-4">
        <span class="bx bxs-plus-circle float-end fa-2x" style="color: #2a3042 !important"></span>
      </button>
    </div>
  </div>

  <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
    <thead>
      <tr class="text-light text-center" style="background-color: #2a3042 !important">
        <th scope="col">{{ $t("marketing.id") }}</th>
        <th scope="col">{{ $t("marketing.name") }}</th>
        <th scope="col">{{ $t("marketing.descr") }}</th>
        <th scope="col">{{ $t("marketing.created") }}</th>
        <th scope="col">{{ $t("marketing.updated") }}</th>
        <th scope="col">{{ $t("marketing.operations") }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(app, i) in MarketingChannels" :key="app" class="text-center">
        <td>{{ i + 1 }}</td>
        <td>{{ app?.name }}</td>
        <td>{{ app?.descr ? app?.descr : "--" }}</td>
        <td>{{ app?.created?.split("T")[0] }}</td>
        <td>{{ app?.updated?.split("T")[0] }}</td>

        <td class="d-flex justify-content-center">
          <button type="button" class="btn btn-primary btn-sm mx-2" data-bs-target="#editModal" data-bs-toggle="modal"
            @click="setSelectedChannel(app)">
            {{ $t("popups.edit") }}
          </button>
          <a class="btn btn-danger" href="javascript: void(0);" role="button" @click="deleteMarketingChannels(app)">{{
            $t("popups.delete") }}</a>
        </td>
      </tr>
    </tbody>
  </table>
  <!-- end  table -->

  <!--Start Add Modal-->
  <div class="modal fade" id="addModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
    :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
    <div class="modal-dialog">
      <div class="modal-content">
        <form @submit.prevent="addMarketingChannels()">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t("popups.add") }}
            </h5>

          </div>

          <div class="modal-body">
            <label for="name">{{ $t("popups.name") }}</label>
            <input v-model="data.name" id="name" required class="form-control mb-2" type="text"
              :placeholder="$t('popups.name')" />

            <label for="descr">{{ $t("popups.descr") }}</label>
            <input v-model="data.descr" id="descr" class="form-control mb-2" type="text"
              :placeholder="$t('popups.descr')" />

            <div class="row d-flex align-items-center mt-3">
              <div class="col">
                <label for="user_role">{{
                  $t("popups.channel_levels")
                }}</label>
              </div>
              <div class="col d-flex justify-content-end">
                <button type="button" @click="newLevels.push({})" class="btn float-end">
                  <span class="bx bxs-plus-circle float-end fa-2x" style="color: #2a3042 !important"></span>
                </button>
              </div>
            </div>
            <div v-for="level in newLevels" :key="level">
              <div class="border border-2 px-4 pt-5 pb-4 my-4" style="border-radius: 0.25rem; position: relative">
                <!-- close icon -->
                <div class="d-none d-lg-inline-block ms-1" style="position: absolute; left: -0.5rem; top: -0.8rem"
                  @click="deleteLevel(level)">
                  <button type="button" class="btn header-item noti-icon">
                    <i class="bx bx-window-close text-danger fa-2xl"></i>
                  </button>
                </div>
                <!-- level name -->
                <label for="name">{{ $t("popups.level_name") }}</label>
                <input v-model="level.name" id="name" required class="form-control mb-2" type="text"
                  :placeholder="$t('popups.level_name')" />
                <!-- level descr -->
                <label for="descr">{{ $t("popups.level_descr") }}</label>
                <input v-model="level.descr" id="descr" class="form-control mb-2" type="text"
                  :placeholder="$t('popups.level_descr')" />
              </div>
            </div>
          </div>
          <div class="modal-footer" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
            <button type="submit" class="btn btn-primary">
              {{ $t("popups.add") }}
            </button>
            <button data-bs-dismiss="modal" type="button" class="btn close-modal-btn btn-danger">
              {{ $t("popups.cancel") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!--End Add Modal-->

  <!--Start Edit Modal-->
  <div class="modal fade" id="editModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
    :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
    <div class="modal-dialog">
      <div class="modal-content">
        <form @submit.prevent="editMarketingChannels()">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t("popups.edit") }}
            </h5>

          </div>

          <div class="modal-body">
            <label for="name">{{ $t("popups.name") }}</label>
            <input v-model="selectedChannel.name" id="name" required class="form-control mb-2" type="text"
              :placeholder="$t('popups.name')" />

            <label for="descr">{{ $t("popups.descr") }}</label>
            <input v-model="selectedChannel.descr" id="descr" class="form-control mb-2" type="text"
              :placeholder="$t('popups.descr')" />

            <div class="row d-flex align-items-center mt-3">
              <div class="col">
                <label for="user_role">{{
                  $t("popups.channel_levels")
                }}</label>
              </div>
              <div class="col d-flex justify-content-end">
                <button type="button" @click="selectedChannel?.marketing_channel_levels?.push({})" class="btn float-end">
                  <span class="bx bxs-plus-circle float-end fa-2x" style="color: #2a3042 !important"></span>
                </button>
              </div>
            </div>
            <div v-for="level in selectedChannel?.marketing_channel_levels" :key="level">
              <div class="border border-2 px-4 pt-5 pb-4 my-4" style="border-radius: 0.25rem; position: relative">
                <!-- close icon -->
                <div class="d-none d-lg-inline-block ms-1" style="position: absolute; left: -0.5rem; top: -0.8rem"
                  @click="deleteEdetedLevel(level)">
                  <button type="button" class="btn header-item noti-icon">
                    <i class="bx bx-window-close text-danger fa-2xl"></i>
                  </button>
                </div>
                <!-- level name -->
                <label for="name">{{ $t("popups.level_name") }}</label>
                <input v-model="level.name" id="name" required class="form-control mb-2" type="text"
                  :placeholder="$t('popups.level_name')" />
                <!-- level descr -->
                <label for="descr">{{ $t("popups.level_descr") }}</label>
                <input v-model="level.descr" id="descr" class="form-control mb-2" type="text"
                  :placeholder="$t('popups.level_descr')" />
              </div>
            </div>
          </div>
          <div class="modal-footer" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
            <button type="submit" class="btn btn-primary">
              {{ $t("popups.edit") }}
            </button>
            <button data-bs-dismiss="modal" type="button" class="btn close-modal-btn btn-danger">
              {{ $t("popups.cancel") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!--End Edit Modal-->

  <!-- Button trigger modal -->

  <div v-if="tot_pages == 20">
    <!--   Apps  Pagination     -->
    <ul class="pagination pagination-rounded justify-content-center mb-2">
      <li class="page-item" :class="{ disabled: page == 1 }">
        <a class="page-link" href="javascript: void(0);" @click="get(page - 1)" aria-label="Previous">
          <i class="mdi mdi-chevron-left"></i>
        </a>
      </li>
      <li class="page-item" :class="{ active: p == page }" v-for="p in tot_pages" :key="p">
        <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
          p
        }}</a>
      </li>

      <li class="page-item" :class="{ disabled: page == tot_pages }">
        <a class="page-link" href="javascript: void(0);" @click="get(page + 1)" aria-label="Next">
          <i class="mdi mdi-chevron-right"></i>
        </a>
      </li>
    </ul>
  </div>
  <!--end-->
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>
